<template>
    <div>
        <b-overlay :show="loading" rounded="sm" variant="transparent" spinner-variant="primary">
            <validation-observer ref="simpleRules">
                <b-form>
                    <b-row class="d-flex justify-content-end">
                        <b-col cols="12">
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="float-right"
                                @click="submitForm">
                                <feather-icon icon="CheckIcon" class="mr-25" />
                                <span>Save Changes</span>
                            </b-button>
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="flat-primary"
                                class="float-right mr-1" :href="LINKINBIO_BASE_URL + page.slug"
                                target="_blank">
                                <feather-icon icon="EyeIcon" class="mr-25" />
                                <span>Preview</span>
                            </b-button>
                        </b-col>
                    </b-row>
                    <b-row class="mt-2">
                        <b-col cols="12" sm="12" md="7" lg="7" xl="7">
                            <b-card>
                                <b-row>
                                    <b-col cols="12">
                                        <div class="d-flex align-items-center mb-2">
                                            <feather-icon icon="CopyIcon" size="18" />
                                            <h4 class="mb-0 ml-75">Page</h4>
                                        </div>
                                    </b-col>
                                    <b-col cols="12">
                                        <div class="d-flex justify-content-start align-items-center">
                                            <div>
                                                <span v-if="page.logo.image_url != null" class="d-inline-block m1"
                                                    @mouseenter="overlayImage = true" @mouseleave="overlayImage = false">
                                                    <b-overlay opacity="1" variant="transperant" :show="overlayImage">
                                                        <b-img height="75" width="75" :src="page.logo.image_url"
                                                            class="d-inline-block mr-1 mb-1 m1 border" rounded />
                                                        <template #overlay>
                                                            <div class="text-center">
                                                                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                                                    variant="flat-success" class="btn-icon mr-75 mb-75"
                                                                    size="sm" @click="changeImage('logo')">
                                                                    <feather-icon icon="Edit2Icon" />
                                                                </b-button>
                                                            </div>
                                                        </template>
                                                    </b-overlay>
                                                </span>
                                                <b-link @click="changeImage('logo')" v-else>
                                                    <b-avatar rounded="sm" variant="white" size="75"
                                                        class="d-inline-block mr-1 mb-1 popular">
                                                        <feather-icon icon="PlusIcon" class="text-primary" size="50" />
                                                    </b-avatar>
                                                </b-link>
                                            </div>
                                            <div class="profile-user-info">
                                                <h6 class="mb-0">Logo</h6>
                                                <small class="text-muted">Please enter a valid background logo image</small>
                                            </div>
                                        </div>

                                        <div v-show="false">
                                            <b-form-file v-model="page.logo.image" placeholder="Shoose image file"
                                                drop-placeholder="Drag & frop image file here.." browse-text="Browse"
                                                accept="image/*" ref="logo" @input="createImageUrl('logo')" />
                                        </div>
                                    </b-col>
                                    <b-col cols="12">
                                        <div class="d-flex justify-content-start align-items-center">
                                            <div>
                                                <span v-if="page.header_image.image_url != null" class="d-inline-block m1"
                                                    @mouseenter="overlayImageHeader = true" @mouseleave="overlayImageHeader = false">
                                                    <b-overlay opacity="1" variant="transperant" :show="overlayImageHeader">
                                                        <b-img v-if="page.template != 2" height="75" width="75" :src="page.header_image.image_url"
                                                            class="d-inline-block mr-1 mb-1 m1 border" rounded />
                                                        <b-avatar v-else class="d-inline-block mr-1 mb-1 m1 border" rounded="sm" variant="light-primary" size="75">
                                                            <feather-icon icon="FilmIcon" size="25" />
                                                        </b-avatar>
                                                        <template #overlay>
                                                            <div class="text-center">
                                                                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                                                    variant="flat-success" class="btn-icon mr-75 mb-75"
                                                                    size="sm" @click="changeImage('header_image')">
                                                                    <feather-icon icon="Edit2Icon" />
                                                                </b-button>
                                                            </div>
                                                        </template>
                                                    </b-overlay>
                                                </span>
                                                <b-link @click="changeImage('header_image')" v-else>
                                                    <b-avatar rounded="sm" variant="white" size="75"
                                                        class="d-inline-block mr-1 mb-1 popular">
                                                        <feather-icon icon="PlusIcon" class="text-primary" size="50" />
                                                    </b-avatar>
                                                </b-link>
                                            </div>
                                            <div class="profile-user-info">
                                                <h6 class="mb-0" v-text="page.template != 2 ? 'Background Image': 'Background Video'"></h6>
                                                <small class="text-muted" v-text="page.template != 2 ? 'Please enter a valid background Image': 'Please enter a valid background video'"></small>
                                            </div>
                                        </div>

                                        <div v-show="false">
                                            <b-form-file v-model="page.header_image.image" placeholder="Shoose image file"
                                                drop-placeholder="Drag & frop image file here.." browse-text="Browse"
                                                :accept="page.template == 2 ? 'video/mp4,video/x-m4v,video/*':'image/*'" ref="header_image"
                                                @input="createImageUrl('header_image')" />
                                        </div>
                                    </b-col>
                                    <b-col cols="12">
                                        <b-form-group label="Title" label-for="title">
                                            <validation-provider #default="{ errors }" name="Title" rules="required">
                                                <b-form-input v-model="page.title" :state="errors.length > 0 ? false : null"
                                                    placeholder="title" id="title" />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12">
                                        <b-form-group label="Slug" label-for="slug">
                                            <validation-provider #default="{ errors }" name="Slug" rules="required">
                                                <b-input-group :prepend="LINKINBIO_BASE_URL"
                                                    class="input-group-merge" :state="errors.length > 0 ? false : null">
                                                    <b-form-input :state="errors.length > 0 ? false : null"
                                                        v-model="page.slug" />
                                                </b-input-group>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12">
                                        <b-form-group label="Template" label-for="category">
                                            <validation-provider #default="{ errors }" name="template" rules="required">
                                                <v-select v-model="page.template" id="category" :options="templates"
                                                    label="name" :state="errors.length > 0 ? false : null"
                                                    :reduce="item => item.id" />
                                                <!-- <template #option="{ name, color }">
                                                        <b-badge :variant="color">{{ name }}</b-badge>
                                                    </template>

                                                    <template #selected-option="{ name, color }">
                                                        <b-badge :variant="color">{{ name }}</b-badge>
                                                    </template>
                                                </v-select> -->
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12">
                                        <b-form-group label="Username" label-for="username">
                                            <validation-provider #default="{ errors }" name="Username">
                                                <b-input-group id="username">
                                                    <b-input-group-prepend is-text>
                                                        <feather-icon icon="UserIcon" />
                                                    </b-input-group-prepend>
                                                    <b-form-input dir="ltr" v-model="page.username"
                                                        placeholder="Username" />
                                                </b-input-group>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12">
                                        <b-form-group label="Description" label-for="description">
                                            <validation-provider #default="{ errors }" name="Description">
                                                <b-form-textarea id="description" v-model="page.description" rows="3"
                                                    :state="errors.length > 0 ? false : null" placeholder="Description" />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="2">
                                        <b-form-group label="Primary Color" label-for="name">
                                            <validation-provider #default="{ errors }" name="Primary Color"
                                                rules="required">
                                                <b-form-input v-model="page.primary_color" type="color"
                                                    :state="errors.length > 0 ? false : null" placeholder="Primary Color"
                                                    id="name" />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                        <b-col cols="12" sm="12" md="5" lg="5" xl="5">
                            <Preview class="mb-2" :key="reloadComp" :slug="page.slug_preview" />
                        </b-col>
                    </b-row>
                    <b-card>
                        <b-row>
                            <b-col cols="12">
                                <div class="d-flex align-items-center mb-2">
                                    <feather-icon icon="LayersIcon" size="18" />
                                    <h4 class="mb-0 ml-75">Links</h4>
                                </div>
                            </b-col>
                        </b-row>
                        <div>
                            <b-form ref="form" :style="{ height: trHeight }" class="repeater-form"
                                @submit.prevent="addModalShow = true;">

                                <!-- Row Loop -->
                                <b-row v-for="item in page.elements" :id="item.id" :key="item.id" ref="row">

                                    <!-- Item Name -->
                                    <b-col cols="6" sm="6" md="3" lg="3" xl="3">
                                        <b-form-group label="Title" label-for="item-name">
                                            <validation-provider #default="{ errors }" name="title" rules="required">
                                                <b-form-input id="item-name" v-model="item.title" type="text"
                                                    placeholder="title" />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>

                                    <!-- Cost -->
                                    <b-col cols="6" sm="6" md="3" lg="3" xl="3">
                                        <b-form-group label="Type" label-class="p-0">
                                            <validation-provider #default="{ errors }" name="type" rules="required">
                                                <v-select dir="rlt" v-model="item.type" :options="types"
                                                    placeholder="type" label="name" :reduce="item => item.id" />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>

                                    <!-- Quantity -->
                                    <b-col cols="10" sm="10" md="5" lg="5" xl="5">
                                        <b-form-group label="Link" label-for="Link">
                                            <validation-provider #default="{ errors }" name="Link">
                                                <b-input-group id="url">
                                                    <b-input-group-prepend is-text>
                                                        <feather-icon icon="LinkIcon" />
                                                    </b-input-group-prepend>
                                                    <b-form-input dir="ltr" v-model="item.url" placeholder="link" />
                                                </b-input-group>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>

                                    <!-- Remove Button -->
                                    <b-col cols="2" sm="2" md="1" lg="1" xl="1" class="mb-50">
                                        <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="flat-danger"
                                            class="mt-0 mt-md-2 btn-icon rounded-circle" @click="removeElement(item.id)">
                                            <feather-icon icon="XIcon" />
                                        </b-button>
                                    </b-col>

                                    <b-col cols="12">
                                        <hr>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </div>
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="addElement">
                            <feather-icon icon="PlusIcon" class="mr-25" />
                            <span>add link</span>
                        </b-button>
                    </b-card>
                    <b-row class="d-flex justify-content-end">
                        <b-col cols="12">
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="float-right"
                                @click="submitForm">
                                <feather-icon icon="CheckIcon" class="mr-25" />
                                <span>Save Changes</span>
                            </b-button>
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="flat-primary"
                                class="float-right mr-1" :href="LINKINBIO_BASE_URL + page.slug"
                                target="_blank">
                                <feather-icon icon="EyeIcon" class="mr-25" />
                                <span>Preview</span>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-overlay>
    </div>
</template>
  
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required, email } from '@validations'
import {
    BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BCard, BFormCheckbox, BBadge, BInputGroup, BOverlay, BSpinner,
    BInputGroupAppend, BInputGroupPrepend, BFormFile, BAvatar, BLink, BImg, BFormTextarea, BEmbed
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import axios from 'axios'
import Preview from './components/Preview.vue'

export default {
    components: {
        Preview, ValidationProvider, ValidationObserver, ToastificationContent, vSelect,
        BForm, BRow, BCol, BButton, BFormGroup, BFormInput, BCard, BFormCheckbox, BBadge, BInputGroup, BOverlay, BSpinner,
        BInputGroupAppend, BInputGroupPrepend, BFormFile, BAvatar, BLink, BImg, BFormTextarea, BEmbed
    },
    directives: {
        Ripple,
    },
    mixins: [heightTransition],
    data() {
        return {
            LINKINBIO_BASE_URL: process.env.VUE_APP_LINKINBIO_BASE_URL,
            overlayImage: false,
            overlayImageHeader: true,
            addModalShow: false,
            loadingElement: false,
            reloadComp: 1,
            loading: true,
            page: {
                id: null,
                template: '',
                slug: '',
                slug_preview: '',
                username: '',
                description: '',
                title: '',
                primary_color: '',
                logo: {
                    image: null,
                    image_url: null
                },
                header_image: {
                    image: null,
                    image_url: null
                },
                elements: []
            },
            types: [],
            templates: [],
            element: {
                name: '',
                type_id: null,
                required: false,
                options: []
            },
            required, email
        }
    },
    mounted() {
        localize('ar')
        this.getTypes()
        this.getTemplates()
        this.getPage()
    },
    methods: {
        addElement() {
            this.page.elements.push({ id: new Date().getTime(), type: 1, title: 'Facebook', url: 'https://facebook.com/' })
        },
        changeImage(content) {
            this.$refs[content].$refs.input.click()
        },
        createImageUrl(content) {
            console.log(content);
            if (this.page[content].image != null) {
                this.page[content].image_url = URL.createObjectURL(this.page[content].image);
            }
        },
        async submitForm() {
            this.loading = true;
            await this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    const data = {
                        id: this.page.id,
                        template: this.page.template,
                        slug: this.page.slug,
                        username: this.page.username,
                        description: this.page.description,
                        title: this.page.title,
                        primary_color: this.page.primary_color,
                        header_image: this.page.header_image,
                        elements: this.page.elements
                    }
                    let formData = new FormData()
                    formData.append('template', this.page.template)
                    formData.append('slug', this.page.slug)
                    formData.append('username', this.page.username)
                    formData.append('description', this.page.description)
                    formData.append('title', this.page.title)
                    formData.append('primary_color', this.page.primary_color)
                    formData.append('elements', JSON.stringify(this.page.elements))
                    if (this.page.logo.image != null) {
                        formData.append('logo', this.page.logo.image)
                    }
                    if (this.page.header_image.image != null) {
                        formData.append('header_image', this.page.header_image.image)
                    }
                    axios.put(`/pages/${this.$route.params.id}/`, formData, { headers: { "Content-Type": "multipart/form-data" } })
                        .then((response) => {
                            console.log(response);
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Page Saved',
                                    icon: 'CheckIcon',
                                    text: 'Page was saved successfully.',
                                    variant: 'success',
                                },
                            })

                            this.getPage()
                            this.$refs.simpleRules.reset();
                        })
                        .catch((error) => {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Warning !',
                                    icon: 'AlertCircleIcon',
                                    text: 'Something Went Wrong.',
                                    variant: 'danger',
                                },
                            })
                            this.loading = false;
                            JSON.stringify(error);
                        })
                } else {
                    this.loading = false;
                }
            })
        },
        removeElement(id) {
            this.page.elements = this.page.elements.filter(item => item.id != id)
        },
        getTypes() {
            axios.get('pages/element_types/')
                .then(response => {
                    console.log(response);
                    this.types = response.data
                })
                .catch(error => {
                    console.log(error);
                })
        },
        getTemplates() {
            axios.get("/pages/templates/")
                .then(response => {
                    console.log(response);
                    this.templates = response.data
                })
                .catch(error => {
                    console.log(error);
                })
        },
        async getPage() {
            this.loading = true
            await axios.get(`/pages/${this.$route.params.id}/`)
                .then(response => {
                    this.loading = false
                    console.log(response);
                    this.page.id = response.data.id
                    this.page.template = response.data.template
                    this.page.slug = response.data.slug
                    this.page.slug_preview = response.data.slug
                    this.page.username = response.data.username
                    this.page.description = response.data.description
                    this.page.title = response.data.title
                    this.page.primary_color = response.data.primary_color
                    this.page.logo.image_url = response.data.logo
                    this.page.header_image.image_url = response.data.header_image
                    this.page.elements = response.data.elements
                    // response.data.elements.forEach(item => {
                    //     this.page.elements.push({ index: 0, id: item.id, type_id: item.type, title: item.title, url: item.url })
                    // })
                    this.reloadComp += 1
                })
                .catch(error => {
                    console.log(error);
                })
        }
    },
}
</script>
  
<style lang="scss" scoped></style>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>


<style>
.popular {
    border: 1px solid #7367f0 !important;
    color: #7367f0;
}

.theme {
    border: 1px solid rgba(0, 0, 0, .1) !important;
    border-radius: 3px;
}
</style>