<style>
.mp-frame {
    position: relative;
    max-width: 285px;
    margin-left: auto;
    margin-right: auto;
    padding: 15px;
    background: url(~@/assets/images/mobile-preview/phone-frame.png);
    background-size: 100%;
    border-radius: 40px;
}

.mp-frame::before {
    content: "";
    position: absolute;
    top: 22px;
    width: 59px;
    height: 17px;
    left: 50%;
    transform: translateX(-50%);
    background: url(~@/assets/images/mobile-preview/phone_bar.jpg);
    background-size: 100%;
    border-radius: 10px;
    z-index: 10;
}

.mp-container {
    font-family: "HelveticaNeueLT Arabic 55 Roman", sans-serif;
    width: 100%;
    overflow-y: scroll;
    background-color: #fff;
    border-radius: 31px;
    height: 550px;
}

.mp-container p,
.mp-container span,
.mp-container h1,
.mp-container h2,
.mp-container h3,
.mp-container h4 {
    text-align: right;
}

.mp-container::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.swiper-slide.active {
    background-color: transparent !important;
    border: 1px solid #000 !important;
}

#quantity-minus.disabled {
    color: #d4d7b3 !important;
}
</style>

<template>
    <div class="mp-frame">
        <div class="mp-container">
            <iframe :src="baseUrl+slug" width="100%" height="100%" frameborder="0"></iframe>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        slug: {
            type: String,
            default: 'templates/template_one'
        }
    },
    data() {
        return {
            baseUrl: process.env.VUE_APP_LINKINBIO_BASE_URL
        }
    }
};
</script>